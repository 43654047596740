import { ReactElement } from "react";
import { Box, Tooltip, Typography } from "@mui/material";
import { ScoreRating, ScoreResultType, ScoreState } from "~/operations";
import { useCheckResultByText } from "./UseCheckResultByText";
import { CheckResultIcon } from "~/components/print/CheckResultIcon";
import { ImpactRating } from "../impact-rating";

type CheckResultByTextProps = {
  scoreResult: ScoreResultType;
  scoreState: ScoreState;
  rating: ScoreRating;
  hideTooltip?: boolean;
};

export const CheckResultByText = ({
  scoreResult,
  scoreState,
  rating,
  hideTooltip,
}: CheckResultByTextProps) => {
  const { result, label, color, impactNumber } = useCheckResultByText({
    scoreState,
    scoreResult,
    rating,
  });

  const TooltipWrapper = ({ children }: { children: ReactElement }) =>
    hideTooltip ? (
      children
    ) : (
      <Tooltip
        title={
          <Box>
            {result && (
              <Typography fontSize={10} fontWeight="700">
                RISK:{" "}
                <Typography
                  variant="caption"
                  fontSize={10}
                  color={color}
                  fontWeight="700"
                >
                  {label.toUpperCase()}
                </Typography>
              </Typography>
            )}
          </Box>
        }
        componentsProps={{
          tooltip: {
            sx: {
              maxWidth: "none",
              p: 1,
            },
          },
        }}
        placement="top"
        arrow
      >
        {children}
      </Tooltip>
    );

  return (
    <TooltipWrapper>
      <Box display="inline-flex" gap={1}>
        <CheckResultIcon
          scoreResult={result}
          scoreState={scoreState}
          rating={rating}
        />

        <Box className="impact" display="inline-flex" flexDirection="column">
          <Box className="impact-label" mb="4px">
            <Typography
              sx={{ fontWeight: 400, fontSize: 12, textTransform: "uppercase" }}
            >
              {label}
            </Typography>
          </Box>

          <Box className="impact-rating">
            <ImpactRating impactData={impactNumber} color={color} />
          </Box>
        </Box>
      </Box>
    </TooltipWrapper>
  );
};
