import { alpha, Box, useTheme } from "@mui/material";
import {
  ArrowRightAltIcon,
  CheckIcon,
  CloseIcon,
  RemoveIcon,
  WarningAmberIcon,
} from "~/components/icons";
import { ScoreRating, ScoreResultType, ScoreState } from "~/operations";
import { BlockOutlined } from "@mui/icons-material";
import { getColor } from "~/lib/colors";
import { ScoreRatingColorMap } from "~/pages/reporting/reports/lib/ScoreRatingColorMap";

type Props = {
  scoreResult: ScoreResultType | undefined;
  scoreState: ScoreState | undefined;
  rating?: ScoreRating;
};

export function CheckResultIcon({ scoreResult, scoreState, rating }: Props) {
  const theme = useTheme();
  const iconStyles = {
    fontSize: 24,
    diameter: theme.spacing(4),
  };

  const color = getCheckColor({ scoreState, scoreResult, rating });

  const getIcon = () => {
    if (scoreResult === ScoreResultType.Error) {
      return <WarningAmberIcon sx={{ ...iconStyles, color }} />;
    }

    if (scoreResult === ScoreResultType.Skipped) {
      return <ArrowRightAltIcon sx={{ ...iconStyles, color }} />;
    }

    switch (scoreState) {
      case ScoreState.Closed:
        return <CheckIcon sx={{ ...iconStyles, color }} />;

      case ScoreState.Open:
        return <CloseIcon sx={{ ...iconStyles, color }} />;

      case ScoreState.Disabled:
        return <BlockOutlined sx={{ ...iconStyles, color }} />;

      default:
        //skipped
        return <RemoveIcon sx={{ ...iconStyles, color }} />;
    }
  };

  return (
    <Box
      sx={{
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
        width: iconStyles.diameter,
        height: iconStyles.diameter,
        borderRadius: "50%",
        background: alpha(color, 0.1),
      }}
    >
      {getIcon()}
    </Box>
  );
}

type GetCheckColorProps = {
  scoreState: ScoreState | undefined;
  scoreResult: ScoreResultType | undefined;
  rating?: ScoreRating;
};

export const getCheckColor = ({
  scoreState,
  scoreResult,
  rating,
}: GetCheckColorProps) => {
  const theme = useTheme();

  if (!scoreState) {
    return getColor(theme, "unknown");
  }

  let color = getColor(theme, scoreState);

  if (rating) {
    const rateName = ScoreRatingColorMap[rating];
    color = theme.palette[rateName].main;
  }

  if (scoreResult && scoreResult !== ScoreResultType.Result) {
    if (scoreResult === ScoreResultType.Error) {
      return getColor(theme, "errorscan");
    }

    return getColor(theme, scoreResult);
  }

  return color;
};
